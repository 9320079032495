import React from 'react';
import '../css/footer.css';
import PrivacyPolicy from '../privacypolicypdf/fincittapay-privacy-policy.pdf';


const Footer = () => {
    const pdfUrl = 'https://fincittapay.s3.ap-south-1.amazonaws.com/fincittapay++privacy+and+policy.pdf';
    return (
        <>
            <footer className='footer' style={{ fontFamily: 'Inter, sans-serif', backgroundColor: '#2D0463' }}>
                <div className='container-fluid'>
                    <div className='row text-center' id='Footer'>
                        <div className='col-12 col-lg-6'>
                            <div className=''>© 2023 FincittaPay, All Rights Reserved</div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='privacy-policy'>
                                <a href={PrivacyPolicy}
                                    target="_blank"
                                    rel="noreferrer">Terms of Use | Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;