import React, { useState, useRef, useEffect } from "react";
import * as emailjs from "emailjs-com";
import Model from "react-modal";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import axios from "axios";
import "../css/mainpage.css";
import INRlogo from '../images/Frame.svg';
import USDlogo from '../images/Layer_1.svg';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #ffffff",
  borderRadius: "10px",
  boxShadow: 24,
  fontWeight: "500",
  color: "black",
  p: 3,
};

const MainPage = () => {
  const form = useRef();

  const radioRef = useRef();
  const radioRefSell = useRef();

  const [justifyActive, setJustifyActive] = useState("tab1");
  const [payment, setPayment] = useState(null);
  const [youpay, setYoupay] = useState(null);
  const [getusdvalue, setGetUsdValue] = useState(null);
  // const [inrvalue, setInrValue] = useState(null);
  const [email, setEmail] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");

  const [paymentTypeSell, setPaymentTypeSell] = useState(null);
  const [youpaySell, setYoupaySell] = useState(null);
  const [getINRSell, setINRSell] = useState(null);
  const [sellEmail, setSellEmail] = useState("");
  const [sellPhoneNumber, setSellPhoneNumber] = useState("");
  const [sellAddress, setsellAddress] = useState("");

  const [transactionType, setTransactionType] = useState("Buy");

  const [value, setValue] = useState(false);
  const [usdValue, setUsdValue] = useState(false);
  const [emailValue, setEmailValue] = useState(false);
  const [phonenumberValue, setPhoneNumberValue] = useState(false);
  const [addressValue, setAddressValue] = useState(false);

  const [visible, setVisile] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);

  const [buyUsdValue, setBuyUsdValue] = useState(90.60);
  const [sellUsdValue, setSellUsdValue] = useState(86);

  const pdfUrl =
    "https://fincittapay.s3.ap-south-1.amazonaws.com/fincittapaypdf/Fincittapay+Privacy+Policy.pdf";

  const validationForm = () => {
    if (youpay == "") {
      setValue(true);
      return;
    }
    // if (usdValue == "") {
    //     setUsdValue(true);
    // }
    if (email == "") {
      setEmailValue(true);
    }
    if (phonenumber.length < 10) {
      setPhoneNumberValue(true);
    }
    if (address == "") {
      setAddressValue(true);
    }
  };

  const handleChangePayment = (e) => {
    const target = e.target;
    if (target.checked) {
      setPayment(target.value);
    }
  };

  const handleChangeSellPayment = (e) => {
    const target = e.target;
    if (target.checked) {
      setPaymentTypeSell(target.value);
    }
  };

  const handleJustifyClick = (value) => {
    debugger;
    if (value === justifyActive) {
      return;
    }
    setJustifyActive(value);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       fetch('https://sheetdb.io/api/v1/uxdnx0i90xday')
  //         .then((response) => response.json())
  //         .then((data) => {
  //           setData(data);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchData();
  //   // getDataForIndex();
  // }, [0]);

  // const dataBuyValues = data.map((item, index) => {
  //   return item.BUY;
  // });
  // console.log(dataBuyValues);

  // const dataSellValue = data.map((item, index) => {
  //   return item.SELL;
  // });
  // console.log(dataSellValue);


  function changeInrtoUsd(event) {
    setYoupay(event.target.value);
    const buyValue = event.target.value;
    const buyInrtoUsd = parseFloat(buyValue / buyUsdValue).toFixed(2);
    console.log(buyInrtoUsd);
    setGetUsdValue(buyInrtoUsd);
  }

  function changeUsdtoInr(event) {
    setYoupaySell(event.target.value);
    const sellValue = event.target.value;
    const sellUsdtoInr = parseFloat(sellValue * sellUsdValue).toFixed(2);
    console.log(sellUsdtoInr);
    setINRSell(sellUsdtoInr);
  }

  // function handleChangeINRtoUSD(event) {
  //     // console.log(event.target.value);
  //     setYoupay(event.target.value);
  //     var myHeaders = new Headers();
  //     myHeaders.append("apikey", "PjdjjWTm02T6gxiScrYsFYetB8Pu2Xm9");

  //     var requestOptions = {
  //         method: 'GET',
  //         redirect: 'follow',
  //         headers: myHeaders
  //     };

  //     fetch(`https://api.apilayer.com/fixer/convert?to=USD&from=INR&amount=${event.target.value}`, requestOptions)
  //         .then(response => response.json())
  //         .then(data => {
  //             const result = parseFloat(data.result).toFixed(2);
  //             console.log(result, "success");
  //             setGetUsdValue(result);

  //         })
  //         .catch(error => console.log('error', error));
  // }

  // function handleChangeUSDtoINR(event) {
  //     setYoupaySell(event.target.value);
  //     var myHeaders = new Headers();
  //     myHeaders.append("apikey", "PjdjjWTm02T6gxiScrYsFYetB8Pu2Xm9");

  //     var requestOptions = {
  //         method: 'GET',
  //         redirect: 'follow',
  //         headers: myHeaders
  //     };

  //     fetch(`https://api.apilayer.com/fixer/convert?to=INR&from=USD&amount=${event.target.value}`, requestOptions)
  //         .then(response => response.json())
  //         .then(data => {
  //             const result = parseFloat(data.result).toFixed(2);
  //             console.log(result, "success");
  //             setINRSell(result);

  //         })
  //         .catch(error => console.log('error', error));
  // }
  const buySheetdetails = async () => {
    const data = {
      TransactionType: transactionType,
      Payment: payment,
      YouPay: youpay,
      YouGet: getusdvalue,
      YourEmail: email,
      PhoneNumber: phonenumber,
      Address: address,
    };
    const response = await axios
      .post(
        "https://sheet.best/api/sheets/9064c0d0-6fcd-42bc-97be-d4ec3f508549",
        data
      )
      .then((response) => {
        console.log(response);
        console.log("success send to the data");
        setYoupay("");
        setGetUsdValue("");
        setEmail("");
        setPayment(null);
        radioRef.current.checked = false;
        setPhoneNumber("");
        setAddress("");
      });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_8guahzw",
        "template_r0idorz",
        e.target,
        "aP1QlsYSBdQewyLgB"
      )
      .then(
        (result) => {
          console.log("successfully submited");
          console.log(result.text);
          setVisile(true);
          setMessage(
            "Your request has been received and a customer support executive will reach you shortly to complete the transaction"
          );
        },
        (error) => {
          console.log(error.text);
          setMessage("Your Transaction not completed ");
        }
      );

    emailjs
      .sendForm(
        "service_8guahzw",
        "template_3v7a0oo",
        e.target,
        "aP1QlsYSBdQewyLgB"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("successfully submited customer");
          buySheetdetails();
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    setGetUsdValue("");
    setYoupay("");
    setEmail("");
    setPayment("");
    setPhoneNumber("");
    setAddress("");
  };

  const sellSheetDetails = async () => {
    const dataSell = {
      PaymentTypeSell: paymentTypeSell,
      YoupaySell: youpaySell,
      GetINRSell: getINRSell,
      SellEmail: sellEmail,
      SellPhoneNumber: sellPhoneNumber,
      SellAddress: sellAddress,
    };
    const response = await axios
      .post(
        "https://sheet.best/api/sheets/5b81023d-8988-4815-a3a0-9fe34c494f79",
        dataSell
      )
      .then((response) => {
        console.log(response);
        setYoupaySell("");
        setINRSell("");
        setSellEmail("");
        setPaymentTypeSell(null);
        radioRefSell.current.checked = false;
        setSellPhoneNumber("");
        setsellAddress("");
      });
  };

  const sendSellEmail = async (e) => {
    try {
      e.preventDefault();
      console.log("justifyactive", justifyActive);
      const result = await emailjs
        .sendForm(
          "service_8guahzw",
          "template_r0idorz",
          e.target,
          "aP1QlsYSBdQewyLgB"
        )
        .then((result) => {
          console.log("successfully submited");
          console.log(result.text);
          setVisile(true);
          setMessage(
            "Your request has been received and a customer support executive will reach you shortly to complete the transaction."
          );
        });
    } catch (error) {
      console.log(error.text);
      setMessage("Your Transaction not completed ");
    }

    emailjs
      .sendForm(
        "service_8guahzw",
        "template_3v7a0oo",
        e.target,
        "aP1QlsYSBdQewyLgB"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("successfully submited customer");
          sellSheetDetails();
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setYoupaySell("");
    setINRSell("");
    setSellEmail("");
    setPaymentTypeSell(null);
    setSellPhoneNumber("");
    setsellAddress("");
  };

  return (
    <div
      className="container-fluid"
      style={{ fontFamily: "Inter, sans-serif", minHeight: "100vh" }}
    >
      <div className="row">
        <div className="col-12 col-lg-7">
          <div className="logo-section">
            <img
              src={require("../images/WhatsApp Image 2023-11-02 at 11.07 1.png")}
              alt="logo"
            />
          </div>
          <div className="heading">
            <div className="heading-title">
              <p style={{ fontFamily: "Inter, sans-serif" }}>
                <b>Buy & Sell </b>USDT in INR <br /> With Local{" "}
                <b>Bank Transfer </b>
              </p>
            </div>
          </div>
          <div>
            <div className="ekyc-section">
              <h2 className="ekyc-heading">e-KYC not required</h2>
            </div>
          </div>
          <div className="steps-section">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="card-title"> How to Exchange ?</h2>
              </div>
            </div>
            <div className="row" style={{ marginTop: "35px" }}>
              <div className="col-6 col-lg-6">
                <div className="step1-section">
                  <p className="step-title-2">STEP 1</p>
                  <p className="step-title-4">
                    Fill out the <br /> required information
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="step1-section">
                  <p className="step-title-2">STEP 2</p>
                  <p className="step-title-4">
                    Receive the <br /> email confirmation
                  </p>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "35px" }}>
              <div className="col-6 col-lg-6">
                <div className="step1-section">
                  <p className="step-title-2">STEP 3</p>
                  <p className="step-title-4">
                    You will be contacted <br /> by the account manager
                  </p>
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="step1-section">
                  <p className="step-title-2">STEP 4</p>
                  <p className="step-title-4">
                    Complete <br /> your transaction
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='col-sm-0 col-md-2 col-lg-0'></div> */}
        <div className="col-md-12 col-lg-5">
          <div className="tab-alignment">
            <div className="tab-container">
              <div id="tab-section">
                <MDBContainer className=" d-flex flex-column ">
                  <MDBTabs
                    pills
                    justify
                    className="mb-3 d-flex flex-row justify-content-between"
                  >
                    <MDBTabsItem>
                      <MDBTabsLink
                        className="Buy-section-tab"
                        onClick={() => handleJustifyClick("tab1")}
                        active={justifyActive === "tab1"}
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "rgba(35, 27, 115, 1)",
                        }}
                      >
                        BUY
                      </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                      <MDBTabsLink
                        onClick={() => handleJustifyClick("tab2")}
                        active={justifyActive === "tab2"}
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "rgba(35, 27, 115, 1)",
                        }}
                      >
                        Sell
                      </MDBTabsLink>
                    </MDBTabsItem>
                  </MDBTabs>

                  <MDBTabsContent>
                    <MDBTabsPane show={justifyActive === "tab1"}>
                      <div className="form-section">
                        <div className="mb-3">
                          <p
                            className="Payment-method"
                            style={{ fontFamily: "Inter, sans-serif" }}
                          >
                            Payment Method{" "}
                          </p>
                        </div>
                        <form onSubmit={sendEmail}>
                          <div>
                            <input
                              type="text"
                              value="Buy"
                              name="transactionType"
                              style={{ display: "none" }}
                              readOnly={true}
                            />
                          </div>
                          <div className="mb-2 radio-buttons">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="payment"
                                value="cash"
                                ref={radioRef}
                                checked={payment === "cash"}
                                onChange={(e) => {
                                  setPayment("cash");
                                }}
                                required
                              />{" "}
                              &nbsp; Cash
                            </label>{" "}
                            &nbsp; &nbsp;
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="payment"
                                value="bank"
                                ref={radioRef}
                                checked={payment === "bank"}
                                onChange={(e) => {
                                  setPayment("bank");
                                }}
                                required
                              />{" "}
                              &nbsp; Bank Account
                            </label>
                          </div>
                          <div>
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "rgba(47, 46, 46, 1)",
                              }}
                            >
                              1 USDT is Roughly
                            </span>{" "}
                            &nbsp;
                            <span>
                              <b style={{ fontSize: "25px" }}>{buyUsdValue}</b>
                              <i style={{ fontWeight: "500" }}>INR</i>
                            </span>
                          </div>
                          <div className="mt-3">
                            <label className="mb-2"> You Pay</label>
                            <div className="icon-input">
                              <input
                                type="number"
                                name="youpay"
                                min="1"
                                max="9999999999"
                                value={youpay}
                                onChange={(e) => {
                                  changeInrtoUsd(e);
                                }}
                                required
                              />
                              <img className="icon" src={INRlogo} alt="indian rupee logo" width={40} height={25} />
                              <b className="icons">INR</b>
                            </div>
                          </div>
                          <div className="mt-2">
                            <label className="mb-2">
                              {" "}
                              You Will Receive Roughly
                            </label>
                            <div className="icon-input">
                              <input
                                type="number"
                                name="youget"
                                value={getusdvalue}
                                onChange={(e) => {
                                  setGetUsdValue(e.target.value);
                                }}
                                required
                              />
                              <img className="usdt-icon" src={USDlogo} alt="USD logo" width={20} height={20} />
                              <b className="usd-icons">USDT</b>
                            </div>
                            {/* <label className='error-message'>USDT amount should be more than 100 and less than 6000</label> */}
                          </div>
                          <div className="mt-2">
                            <label className="mb-2"> E-mail</label>
                            <div className="icon-input">
                              <input
                                type="email"
                                name="email"
                                pattern="+@example\.com"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                                required
                              />
                            </div>
                            {emailValue ? (
                              <p style={{ fontSize: "11px", color: "red" }}>
                                invalid email id
                              </p>
                            ) : null}
                          </div>
                          <div className="mt-2">
                            <label className=""> Phone Number</label>
                            <div className="icon-input">
                              <input
                                type="tel"
                                name="phonenumber"
                                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                value={phonenumber}
                                onChange={(e) => {
                                  setPhoneNumber(e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <label className="mb-2"> USDT Wallet Address</label>
                            <textarea
                              id="textarea-address"
                              name="Address"
                              value={address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                              required
                            ></textarea>
                          </div>

                          <button
                            className="mb-4"
                            type="submit"
                            id="exchange-button"
                          >
                            EXCHANGE
                          </button>
                        </form>
                      </div>
                    </MDBTabsPane>

                    <MDBTabsPane show={justifyActive === "tab2"}>
                      <div className="form-section">
                        <div className="mb-3">
                          <p className="Payment-method">Payment Method </p>
                        </div>
                        <form onSubmit={sendSellEmail}>
                          <div>
                            <input
                              type="text"
                              value="Sell"
                              name="transactionType"
                              style={{ display: "none" }}
                              readOnly={true}
                            />
                          </div>
                          <div className="mb-2 radio-buttons">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="payment"
                                value="cash"
                                ref={radioRefSell}
                                checked={paymentTypeSell === "cash"}
                                onChange={(e) => {
                                  setPaymentTypeSell("cash");
                                }}
                                required
                              />{" "}
                              &nbsp; Cash
                            </label>{" "}
                            &nbsp; &nbsp;
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="payment"
                                value="bank"
                                ref={radioRefSell}
                                checked={paymentTypeSell === "bank"}
                                onChange={(e) => {
                                  setPaymentTypeSell("bank");
                                }}
                                required
                              />{" "}
                              &nbsp; Bank Account
                            </label>
                          </div>
                          <div>
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "rgba(47, 46, 46, 1)",
                              }}
                            >
                              1 USDT is Roughly
                            </span>{" "}
                            &nbsp;
                            <span>
                              <b style={{ fontSize: "25px" }}>{sellUsdValue}</b>
                              <i style={{ fontWeight: "500" }}>INR</i>
                            </span>
                          </div>
                          <div className="input-label mt-2">
                            <label className="mb-2"> You Pay</label>
                            <div className="icon-input">
                              <input
                                type="number"
                                name="youpay"
                                min="1"
                                max=""
                                value={youpaySell}
                                onChange={(e) => {
                                  changeUsdtoInr(e);
                                }}
                                required
                              />
                              <img src={USDlogo} alt="USD logo" className="usdt-icon" width={20} height={20} />
                              <b className="usd-icons">USDT</b>
                            </div>
                            {/* <label className='error-message'>USDT amount should be more than 100 and less than 6000</label> */}
                          </div>
                          <div className="mt-2">
                            <label className="mb-2">
                              {" "}
                              You Will Receive Roughly
                            </label>
                            <div className="icon-input">
                              <input
                                type="number"
                                name="youget"
                                value={getINRSell}
                                required
                                onChange={(e) => {
                                  setINRSell(e.target.value);
                                }}
                              />
                              <img src={INRlogo} className="icon" alt="Indian Rupee Logo" width={40} height={25} />
                              <b className="icons">INR</b>
                            </div>
                          </div>
                          <div className="mt-2">
                            <label className="mb-2"> E-mail</label>
                            <div className="icon-input">
                              <input
                                type="email"
                                name="email"
                                pattern="+@example\.com"
                                value={sellEmail}
                                onChange={(e) => {
                                  setSellEmail(e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <label className="mb-2"> Phone Number</label>
                            <div className="icon-input">
                              <input
                                type="tel"
                                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                name="phonenumber"
                                value={sellPhoneNumber}
                                onChange={(e) => {
                                  setSellPhoneNumber(e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="mb-4 mt-4"
                            id="exchange-button"
                          >
                            EXCHANGE
                          </button>
                        </form>
                      </div>
                    </MDBTabsPane>
                  </MDBTabsContent>
                </MDBContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal section */}
      <div className="">
        {/* <Button
          onClick={() => {
            setVisile(true);
          }}
        >
          Open modal
        </Button> */}
        <Modal
          keepMounted
          open={visible}
          onClose={() => {
            setVisile(false);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h8"
              sx={{ mt: 1 }}
            >
              {message}
            </Typography>{" "}
            <br />
            <Box textAlign="center">
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setVisile(false);
                }}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>

      {/* How to Exchange section */}
      <div
        className="mt-3"
        id="exchange-section-mobile"
        style={{ width: "100%" }}
      >
        <div
          className="text-center pt-4"
          style={{ fontFamily: "Inter, sans-serif" }}
        >
          <h1 className="card-title text-white mt-5">How To Exchange?</h1>
        </div>{" "}
        <br /> <br />
        <div
          className="row text-center"
          style={{ fontFamily: "Inter, sans-serif" }}
        >
          <div className="col-12 col-lg-3 mb-4">
            <div className="step1-section">
              <p className="step-title-2">STEP 1</p>
              <p className="step-title-4">
                {" "}
                Fill out the required <br /> information{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-3 mb-4">
            <p className="step-title-2">STEP 2</p>
            <p className="step-title-4">
              {" "}
              Receive the email <br /> confirmation{" "}
            </p>
          </div>
          <div className="col-12 col-lg-3 mb-4">
            <p className="step-title-2">STEP 3</p>
            <p className="step-title-4">
              {" "}
              You will be contacted by <br /> the account manager{" "}
            </p>
          </div>
          <div className="col-12 col-lg-3 mb-4">
            <p className="step-title-2">STEP 4</p>
            <p className="step-title-4">
              {" "}
              Complete your <br /> transaction{" "}
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default MainPage;
