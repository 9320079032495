import MainNavbar from './components/Navbar';
import MainPage from './components/MainPage';
import Footer from './components/Footer';
import IconInput from './components/icons';
import AdminDashboard from './components/Admin';

function App() {
  return (
    <div className="" style={{backgroundColor:"#100D26", minHeight: '100vh'}}>
      {/* <AdminDashboard /> */}
      <MainPage />
      <Footer />
    </div>
  );
}

export default App;
